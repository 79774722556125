import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import MyLink from "../shared/sub/myLink"
import ApplyContentStyle from "./ApplyContent.style"

const ApplyContent = () => {
  const { applyPage } = useStaticQuery(graphql`
    query {
      applyPage: sanityApplyPage {
        applyBlocks {
          title
          description
          buttonLink {
            url
            linkText
          }
          icon {
            ...sanityFlightImage
          }
        }
        subDescription
        mainDescription
      }
    }
  `)

  return (
    <>
      <ApplyContentStyle>
        <div className="blocks-container">
          <p className="main-description">{applyPage.mainDescription}</p>
          <p className="sub-description">{applyPage.subDescription}</p>
          {applyPage.applyBlocks.map(block => (
            <div className="block" key={block.buttonLink.linkText}>
              <div className="blocks-content">
                <img
                  className="block-icon"
                  src={block.icon.asset.fluid.src}
                  alt={block.icon.altText}
                />
                <p className="block-title">{block.title}</p>
                <p className="block-description">{block.description}</p>
                <MyLink
                  className="theme-btn block red"
                  url={block.buttonLink.url}
                >
                  {block.buttonLink.linkText}
                </MyLink>
              </div>
            </div>
          ))}
        </div>
      </ApplyContentStyle>
    </>
  )
}

export default ApplyContent
