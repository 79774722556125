import React from 'react'
import ApplySEO from '../components/apply/applySEO'
import ApplyHeader from '../components/apply/header'
import ApplyContent from '../components/apply/ApplyContent'

const Apply = () => {
  return (
    <div className="subpage-container">
      <ApplySEO />
      <ApplyHeader />
      <ApplyContent />
    </div>
  )
}

export default Apply
