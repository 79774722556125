import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SubHeader from "../shared/subHeader"

const ApplyHeader = () => {
  const {
    sanityApplyPage: { title },
  } = useStaticQuery(graphql`
    query {
      sanityApplyPage {
        title
      }
    }
  `)

  return <SubHeader title={title} />
}

export default ApplyHeader
