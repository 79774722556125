import styled from "styled-components"

export default styled.div`
    color: var(--red);

    .blocks-container {
        display: flex;
        flex-wrap: wrap;
        width: 95%;
        margin: 80px auto 10px auto;
        text-align: center;
    }

    .main-description {
        margin: 0px auto 10px auto;
        width: 60%;
        font-size: 2rem;
        @media only screen and (max-width: 1000px) {
            width: 90%;
            text-align:left;
        }
        @media only screen and (max-width: 600px) {
            font-size: 1.5rem;
        }
    }

    .sub-description {
        width: 60%;
        margin: 0px auto 80px auto;
         @media only screen and (max-width: 1000px) {
            width: 90%;
            text-align:left;
        }
    }

    .block {
        width: 49%;
        background-color: #F1ECE6;
        margin: 0px auto 30px auto;
        @media only screen and (max-width: 600px) {
            width: 90%;
        }
    }

    .blocks-content{
        margin: 10%;
    }

    .block-icon { 
        margin: 0px auto 2% auto;
        width: 10%;
        min-width: 50px;
    }

    .block-title {
        color: var(--red);
        text-align: center;
        font-size: 2rem;
        margin-bottom: 2%;
    }

    .block-description {
        width: 95%;
        margin: 0 auto;
    }

    .theme-btn.block {
        margin-top: 30px;
        width:20%;
        min-width: 120px;
    }

    .close-modal {
        position: relative;
            top: 1%;
    }
`